/* You can add global styles to this file, and also import other style files */

h1, h2, h3 {
    font-family: Muli, sans-serif;
    font-weight: 600;
}

h1 {
    font-size: 2em;
    margin-bottom: 0.5em;
}
h2 {
    font-size: 1.5em;
    margin-bottom: 1em;
}
h3 {
    font-size: 1.2em;
    margin-bottom: 1em;
}

html, body {
    height: 100%;
}

// https://stackoverflow.com/questions/7638677/how-can-i-remove-the-gloss-on-a-select-element-in-safari-on-mac
select{
	background: url(data:image/svg+xml;base64,PHN2ZyBpZD0iTGF5ZXJfMSIgZGF0YS1uYW1lPSJMYXllciAxIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCA0Ljk1IDEwIj48ZGVmcz48c3R5bGU+LmNscy0xe2ZpbGw6I2ZmZjt9LmNscy0ye2ZpbGw6IzQ0NDt9PC9zdHlsZT48L2RlZnM+PHRpdGxlPmFycm93czwvdGl0bGU+PHJlY3QgY2xhc3M9ImNscy0xIiB3aWR0aD0iNC45NSIgaGVpZ2h0PSIxMCIvPjxwb2x5Z29uIGNsYXNzPSJjbHMtMiIgcG9pbnRzPSIxLjQxIDQuNjcgMi40OCAzLjE4IDMuNTQgNC42NyAxLjQxIDQuNjciLz48cG9seWdvbiBjbGFzcz0iY2xzLTIiIHBvaW50cz0iMy41NCA1LjMzIDIuNDggNi44MiAxLjQxIDUuMzMgMy41NCA1LjMzIi8+PC9zdmc+) no-repeat right 2px center;
	-moz-appearance: none; 
	-webkit-appearance: none; 
    appearance: none;
    padding-left: 6px;
    padding-right: 16px;
    border-color: #888;
}

.pointer {
    cursor: pointer;
}